import { createTheme } from '@mui/material';

export const baseTheme = createTheme({
  palette: {
    action: {
      disabledBackground: '#afafaf',
      disabled: '#afafaf',
    },
    grey: {
      '00': '#FFFFFF',
      10: '#FAFAFA',
      20: '#EAF2F0',
      30: '#DBE1E0',
      40: '#C7D1D1',
      50: '#76BEBE',
      60: '#6F8A90',
      70: '#647C82',
      80: '#3F5F69',
      90: '#2D313E',
      100: '#0D1329',
    },
    success: {
      light: '#91CBEC',
      main: '#4683DE',
      dark: '#1E67AB',
    },
    primary: {
      main: '#3F5F69',
    },
    secondary: {
      main: '#2C712A',
    },
  },
});
